import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import SideBar from "@/components/SidebarPlugin";
import VueAnalytics from 'vue-analytics';


import {
  library
} from '@fortawesome/fontawesome-svg-core'
import {
  faGlobe,
  faCheckCircle,
  faCheck,
  faEnvelope
} from '@fortawesome/free-solid-svg-icons'
import {
  faTelegram,
  faTwitter,
  faYoutube,
  faFacebook,
  faReddit,
  faKeybase,
  faGithub,
  faStripeS,
  faWeixin,
  faMedium
} from '@fortawesome/free-brands-svg-icons'
import {
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

library.add(faTelegram, faGlobe, faTwitter, faYoutube, faFacebook, faReddit, faKeybase, faGithub, faStripeS, faCheckCircle, faWeixin, faMedium, faEnvelope)
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Tooltip from 'vue-directive-tooltip';
Vue.use(Tooltip);
Vue.use(SideBar);
Vue.use(VueAnalytics, {
  id: 'UA-173527828-1',
  router
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')