<template>
  <div data-toggle="buttons" class="btn-group btn-group-toggle float-right">
    <label
      class="btn btn-sm btn-primary btn-simple"
      v-for="item in values"
      :key="item"
      :class="{
              'active': item == active,
              'inactive': item != active
              }"
      @click="onSelect(item)"
    >{{item}}</label>
  </div>
</template>

<script>
export default {
  props: {
    values: {
      type: Array,
      required: true
    },
    active: {
      required: true
    },
    onSelect: {
      type: Function,
      required: true
    }
  },
  methods: {}
};
</script>

<style scoped>
.inactive {
  opacity: 0.5;
}
</style>