<template>
  <div>
    <vue-slider v-model="value" :data="data" :marks="marks" @change="onChange"></vue-slider>
  </div>
</template>

<script>
import { numberWithCommas, isEmpty } from "@/lib/misc";
import VueSlider from "vue-slider-component";
import "vue-slider-component/theme/default.css";
export default {
  components: {
    VueSlider,
  },
  props: {
    initialValues: {
      required: false,
    },
    onChange: {
      type: Function,
      required: true,
    },
  },
  methods: {
    numberWithCommas,
  },
  data: function () {
    return {
      value: this.initialValues,
      data: [1, 1000, 50000, 100000, 1000000000],
      //data: [0, 1000000000],
      //marks: [0, 1, 1000, 50000, 100000, 1000000000]
      marks: (val) => {
        let result = {
          label: `${val}`,
        };
        if (val > 1) {
          if (val == 1000000000) result.label = "∞";
          else result.label = `${val / 1000}k`;
        }
        return result;
      },
    };
  },
};
</script>

<style scoped>
.vue-slider {
  padding: 7px 20px 30px 20px !important;
}

.vue-slider-dot-handle,
.vue-slider-process,
.vue-slider-mark-step {
  background-color: #1d8cf8 !important;
}
</style>