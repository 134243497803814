<template>
  <div v-if="$route.params.account_name">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!---->
          <div class="card-header">
            <div class="row">
              <div class="col-sm-12 text-left well">
                <img
                  class="bp-logo"
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).logo_256"
                  :src="$store.getters.vtProxyExtraInfo($route.params.account_name).logo_256"
                />
                <h2 class="bp-title ml-4 mt-3">
                  <span
                    v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).name"
                  >{{$store.getters.vtProxyExtraInfo($route.params.account_name).name}} ({{ $route.params.account_name }})</span>
                  <span v-else>{{ $route.params.account_name }}</span>
                  <h5
                    v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && !isEmpty($store.getters.vtProxyExtraInfo($route.params.account_name).slogan)"
                    class="card-category"
                  >{{ $store.getters.vtProxyExtraInfo($route.params.account_name).slogan }}</h5>
                  <div>
                    <!-- Website -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).website"
                      :href="$store.getters.vtProxyExtraInfo($route.params.account_name).website"
                      target="_blank"
                    >
                      <font-awesome-icon icon="globe" size="lg" />
                    </a>
                    <!-- Telegram -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).telegram"
                      :href="'https://t.me/'+$store.getters.vtProxyExtraInfo($route.params.account_name).telegram"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'telegram']" size="lg" />
                    </a>
                    <!-- Twitter -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).twitter"
                      :href="'https://twitter.com/'+$store.getters.vtProxyExtraInfo($route.params.account_name).twitter"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'twitter']" size="lg" />
                    </a>
                    <!-- Wechat -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).wechat"
                      :href="'https://steemit.org/'+$store.getters.vtProxyExtraInfo($route.params.account_name).wechat"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'weixin']" size="lg" />
                    </a>
                    <!-- Steemit -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).steemit"
                      :href="'https://steemit.org/'+$store.getters.vtProxyExtraInfo($route.params.account_name).steemit"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'stripe-s']" size="lg" />
                    </a>
                  </div>
                </h2>
              </div>
              <div class="col-sm-6"></div>
            </div>
          </div>
          <div class="card-body"></div>
        </div>
      </div>
    </div>

    <!-- Left column -->
    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <!-- Votes-->
          <div class="col-lg-6">
            <div class="card">
              <!---->
              <div class="card-header">
                <h5 class="card-category">Proxied votes</h5>
                <h3
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).real_votes"
                  class="card-title"
                >
                  <i class="tim-icons icon-heart-2 text-success"></i>
                  {{numberWithCommas($store.getters.vtProxyExtraInfo($route.params.account_name).real_votes)}}
                </h3>
                <span
                  class="vote-change text-success"
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name).change_24 >0"
                >+{{numberWithCommas($store.getters.vtProxyExtraInfo($route.params.account_name).change_24)}}</span>
                <span
                  class="vote-change text-danger"
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name).change_24 <0"
                >{{numberWithCommas($store.getters.vtProxyExtraInfo($route.params.account_name).change_24)}}</span>
              </div>
              <div class="card-body">
                <div class="chart-area" :v-if="historicVoteData($route.params.account_name)">
                  <line-chart
                    :chart-data="historicVoteData($route.params.account_name).chartData"
                    :extra-options="historicVoteData($route.params.account_name).extraOptions"
                    ref="historyVoteChart"
                    chart-id="history-vote-chart"
                    :height="150"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
          <!-- Num voters-->
          <div class="col-lg-6">
            <div class="card">
              <!---->
              <div class="card-header">
                <h5 class="card-category">Num voters</h5>
                <h3
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).num_voters"
                  class="card-title"
                >
                  <i class="tim-icons icon-chart-pie-36 text-primary"></i>
                  {{$store.getters.vtProxyExtraInfo($route.params.account_name).num_voters}}
                </h3>
              </div>
              <div class="card-body">
                <div class="chart-area" :v-if="historicVoterData($route.params.account_name)">
                  <line-chart
                    :chart-data="historicVoterData($route.params.account_name).chartData"
                    :extra-options="historicVoterData($route.params.account_name).extraOptions"
                    ref="historyVoteChart"
                    chart-id="history-vote-chart"
                    :height="150"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Philosphy -->
        <div class="row">
          <div class="col-lg-12">
            <div
              v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).philosophy"
              class="card"
            >
              <div class="card-header">
                <h3 class="card-title">Philosphy</h3>
              </div>
              <pre class="card-body">{{ $store.getters.vtProxyExtraInfo($route.params.account_name).philosophy}}</pre>
            </div>
          </div>
        </div>

        <!-- Background -->
        <div class="row">
          <div class="col-lg-12">
            <div
              v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).background"
              class="card"
            >
              <div class="card-header">
                <h3 class="card-title">Background</h3>
              </div>
              <pre class="card-body">{{$store.getters.vtProxyExtraInfo($route.params.account_name).background}}</pre>
            </div>
          </div>
        </div>

        <!-- Votes / Voters -->
        <div class="row">
          <div class="col-lg-8">
            <!-- Top voters -->
            <div
              v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).top_voters"
              class="card"
            >
              <!---->
              <div class="card-header">
                <h5
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).num_voters"
                  class="card-category"
                >{{$store.getters.vtProxyExtraInfo($route.params.account_name).num_voters}} voters</h5>
                <h3
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name).top_voters"
                  class="card-title"
                >Top {{$store.getters.vtProxyExtraInfo($route.params.account_name).top_voters.length}} voters</h3>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table tablesorter">
                    <thead class="text-primary">
                      <tr>
                        <th></th>
                        <th>Account</th>
                        <th>Votes</th>
                      </tr>
                    </thead>
                    <tbody class>
                      <tr
                        v-for="(voter, index) in $store.getters.vtProxyExtraInfo($route.params.account_name).top_voters"
                        :key="voter.owner"
                      >
                        <td>{{index+1}}</td>
                        <td>
                          <a
                            :href="'https://wax.bloks.io/account/'+voter.owner"
                            target="_blank"
                          >{{voter.owner}}</a>
                        </td>
                        <td>{{numberWithCommas(voter.real_votes)}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-4">
            <div
              v-if="$store.getters.vtProxyExtraInfo($route.params.account_name) && $store.getters.vtProxyExtraInfo($route.params.account_name).voting_for"
              class="card"
            >
              <!---->
              <div class="card-header">
                <h5
                  v-if="$store.getters.vtProxyExtraInfo($route.params.account_name).voting_for"
                  class="card-category"
                >{{$store.getters.vtProxyExtraInfo($route.params.account_name).voting_for.length}} producers</h5>
                <h3 class="card-title">Voting for</h3>
              </div>
              <div class="card-body">
                <div class="table-responsive">
                  <table class="table tablesorter">
                    <thead class="text-primary">
                      <tr>
                        <th>Producer</th>
                      </tr>
                    </thead>
                    <tbody class>
                      <tr
                        v-for="producer in $store.getters.vtProxyExtraInfo($route.params.account_name).voting_for"
                        :key="producer"
                      >
                        <td>
                          <router-link :to="'/voteauditor/producer/'+producer">{{producer}}</router-link>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Right column -->
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Last votes</h4>
            <rotate-loader :loading="!$store.getters.vtStreamStarted"></rotate-loader>
          </div>
          <div class="card-body">
            <h5 class>Vote change</h5>
            <vote-filter
              :initialValues="this.$store.getters.vtVoteChangeFilter"
              :onChange="voteFilterChange"
            ></vote-filter>
          </div>
        </div>
        <span
          v-for="(action) in $store.getters.vtActions($route.params.account_name)"
          :key="action.trx_id"
        >
          <vote-box
            :actionData="action.data"
            :date="new Date(action.blockTime)"
            :trxId="action.trxId"
            :voteChange="action.voteChange"
          />
        </span>
        <rotate-loader :loading="$store.getters.vtLoadingTransactions"></rotate-loader>
        <h5
          v-if="!isEmpty($store.getters.vtOldestBlock)"
        >Data up to {{new Date($store.getters.vtOldestBlock.blockTime).toLocaleString()}} (block {{$store.getters.vtOldestBlock.blockNum}})</h5>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          v-if="!$store.getters.vtLoadingTransactions && $store.getters.vtStreamStarted"
          @click="loadMoreData"
        >Load more data</button>
      </div>
    </div>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import Picker from "@/components/Picker";
import VoteBox from "@/components/VoteBox";
import VoteFilter from "@/components/VoteFilter";
import { numberWithCommas, isEmpty } from "@/lib/misc";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
import RotateLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "proxy",
  components: {
    SweetModal,
    SweetModalTab,
    Picker,
    VoteBox,
    LineChart,
    BarChart,
    RotateLoader,
    VoteFilter
  },
  methods: {
    numberWithCommas,
    isEmpty,
    replaceCR(s) {
      return s.replace("\n", "<br />");
    },
    voteFilterChange(values) {
      this.$store.dispatch("setVoteChangeFilter", values);
    },
    loadMoreData() {
      this.$store.dispatch("getMoreActions");
    },
    getProxyInfo(proxyAccount) {
      if (
        proxyAccount &&
        isEmpty(this.$store.getters.vtProxyExtraInfo(proxyAccount))
      ) {
        this.$store
          .dispatch("getVTProxyInfo", proxyAccount)
          .then(this.$store.dispatch("getVTProxyHistoricVote", proxyAccount))
          .catch(err =>
            this.$store.commit("setError", "Error obteniendo datos: " + err)
          );
      }
    },
    historicVoteData(proxy) {
      if (isEmpty(this.$store.getters.vtProxyHistoricVote(proxy, "votes")))
        return false;
      return {
        extraOptions: chartConfigs.historicVoteChartOptions,
        chartData: {
          labels: this.$store.getters.vtProxyHistoricVote(proxy, "votes").dates,
          datasets: [
            {
              label: "Votes",
              fill: true,
              borderColor: "#42b883",
              pointBackgroundColor: "#42b883",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#42b883",
              pointRadius: 4,
              data: this.$store.getters.vtProxyHistoricVote(proxy, "votes")
                .values
            }
          ]
        }
      };
    },
    historicVoterData(proxy) {
      if (isEmpty(this.$store.getters.vtProxyHistoricVote(proxy, "votes")))
        return false;
      return {
        extraOptions: chartConfigs.historicVotersChartOptions,
        chartData: {
          labels: this.$store.getters.vtProxyHistoricVote(proxy, "voters")
            .dates,
          datasets: [
            {
              label: "Voters",
              fill: true,
              borderColor: "#F92D81",
              pointBackgroundColor: "#F92D81",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#F92D81",
              pointRadius: 4,
              data: this.$store.getters.vtProxyHistoricVote(proxy, "voters")
                .values
            }
          ]
        }
      };
    }
  },
  updated() {
    this.getProxyInfo(this.$route.params.account_name);
  },
  mounted() {
    this.getProxyInfo(this.$route.params.account_name);
    if (!this.$store.getters.vtInitialized) {
      this.$store.dispatch("initVT");
    }
  }
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>

<style scoped>
.vote-hr {
  margin-top: 0px;
}
.vote-card-body {
  padding-bottom: 0px;
}
.bp-logo {
  width: 80px;
  height: 80px;
}
.bp-title {
  margin: 0 px;
}
.well {
  display: flex;
  align-items: center;
}
.social-link {
  font-size: 13px;
  margin-left: 5px;
  color: #ba54f5;
}

.card-title {
  font-size: 20px;
}

.card-category {
  font-size: 0.95rem;
}
.vote-change {
  font-size: 0.7rem;
}
pre {
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word;
}

.badge-vote {
  font-size: 0.8rem;
  padding: 0.5rem 0.5rem;
}

.badge-success {
  background-color: #349a63 !important;
}
</style>
