import Vue from 'vue'
import Router from 'vue-router'
import NodeStatus from './views/NodeStatus.vue'
import VoteAuditor from './views/VoteAuditor.vue'
import Producer from './views/Producer.vue'
import Proxy from './views/Proxy.vue'
//import Snapshots from './views/Snapshots.vue'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'nodestatus',
      component: NodeStatus,
      alias: '/nodestatus',
    }, {
      path: '/nodestatus/:symbol',
      name: 'nodestatus_symbol',
      component: NodeStatus,
    },
    {
      path: '/voteauditor',
      name: 'voteauditor',
      component: VoteAuditor,
    }, {
      path: '/voteauditor/producer/:account_name',
      name: 'producer',
      component: Producer,
    }, {
      path: '/voteauditor/proxy/:account_name',
      name: 'proxy',
      component: Proxy,
    },
    /*{
          path: '/snapshots',
          name: 'snapshots',
          component: Snapshots,
        }, */
  ],
  scrollBehavior() {
    return {
      x: 0,
      y: 0
    }
  }
})