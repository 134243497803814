<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!---->
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <h2
                  v-if="$store.state.selectedNetwork != 'wax'"
                  class="card-title"
                >
                  {{
                    $store.state.networks[
                      $store.state.selectedNetwork
                    ].name.toUpperCase()
                  }}
                  Node Status
                </h2>
                <img
                  v-if="$store.state.selectedNetwork == 'wax'"
                  src="@/assets/img/guild-status.png"
                  class="mb-2"
                />
                <h5 class="card-category">
                  Last update:
                  {{
                    $store.state.networks[$store.state.selectedNetwork]
                      .last_update
                  }}
                </h5>
                <h5>
                  Status of public infrastructure published on BP's bp.json.
                  <a
                    target="_blank"
                    href="https://github.com/eosrio/bp-info-standard"
                    >BP Info standard</a
                  >
                </h5>
                <div>
                  Curated lists:
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="openModal('p2p-list')"
                  >
                    p2p
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="openModal('api-list')"
                  >
                    api</button
                  >&nbsp;
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="openModal('history-list')"
                  >
                    history
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="openModal('hyperion-list')"
                  >
                    hyperion
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="openModal('atomic-list')"
                  >
                    atomic
                  </button>
                  &nbsp;
                  <button
                    type="button"
                    class="btn btn-info btn-sm"
                    @click="openModal('ipfs-list')"
                  >
                    ipfs
                  </button>
                  &nbsp;
                  <sweet-modal
                    modal-theme="dark"
                    overlay-theme="dark"
                    ref="p2p-list"
                  >
                    <h2>P2P endpoint list</h2>
                    <pre class="text-left">{{ getP2PList() }}</pre>
                  </sweet-modal>
                  <sweet-modal
                    modal-theme="dark"
                    overlay-theme="dark"
                    ref="api-list"
                  >
                    <h2>API endpoint list</h2>
                    <pre class="text-left">{{ getAPIList() }}</pre>
                  </sweet-modal>
                  <sweet-modal
                    modal-theme="dark"
                    overlay-theme="dark"
                    ref="history-list"
                  >
                    <h2>History endpoint list</h2>
                    <pre class="text-left">{{ getHistoryList() }}</pre>
                  </sweet-modal>
                  <sweet-modal
                    modal-theme="dark"
                    overlay-theme="dark"
                    ref="hyperion-list"
                  >
                    <h2>Hyperion history endpoint list</h2>
                    <pre class="text-left">{{ getHyperionList() }}</pre>
                  </sweet-modal>
                  <sweet-modal
                    modal-theme="dark"
                    overlay-theme="dark"
                    ref="atomic-list"
                  >
                    <h2>Atomic API endpoint list</h2>
                    <pre class="text-left">{{ getAtomicList() }}</pre>
                  </sweet-modal>
                  <sweet-modal
                    modal-theme="dark"
                    overlay-theme="dark"
                    ref="ipfs-list"
                  >
                    <h2>IPFS endpoint list</h2>
                    <pre class="text-left">{{ getIPFSList() }}</pre>
                  </sweet-modal>
                </div>
                <br />
              </div>
              <div class="col-sm-6">
                <div
                  data-toggle="buttons"
                  class="btn-group btn-group-toggle float-right"
                ></div>
                <div id="tm-box" class="float-right mr-3 mt-2">
                  <h4>Time Machine</h4>
                  <div>
                    <vue-slider
                      v-model="value"
                      :lazy="true"
                      :data="data"
                      :data-value="'id'"
                      :data-label="'name'"
                      :hide-label="true"
                      :tooltip="'active'"
                      @change="onTMChange"
                    />
                  </div>
                  Date visualized:
                  {{
                    $store.state.networks[$store.state.selectedNetwork].tm_date
                  }}
                </div>
              </div>
            </div>
          </div>
          <!---->
          <!---->
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="card">
          <!---->
          <div class="card-header">
            <h5 class="card-category text-success">Healthy</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-heart-2 text-success"></i>
              {{ filterNodes(0).length }}
            </h3>
          </div>
          <div
            class="card card-bp"
            v-for="node in filterNodes(0)"
            :key="node.account"
          >
            <div class="card-header card-header-bp">
              <div class="card-title card-title-bp">
                <i class="tim-icons icon-button-power text-success"></i>
                <button
                  v-if="$store.state.selectedNetwork == 'fio'"
                  type="button"
                  v-bind:id="'collapser-' + node.account"
                  class="btn btn-light btn-link title btn-bp"
                  data-toggle="collapse"
                  v-bind:data-target="'#collapse-' + node.account"
                  aria-expanded="false"
                >
                  {{ nameLimited(node.org_name) }} ({{ node['fio_address'] }})
                </button>
                <button
                  v-if="$store.state.selectedNetwork != 'fio'"
                  type="button"
                  v-bind:id="'collapser-' + node.account"
                  class="btn btn-light btn-link title btn-bp"
                  data-toggle="collapse"
                  v-bind:data-target="'#collapse-' + node.account"
                  aria-expanded="false"
                >
                  {{ nameLimited(node.org_name) }} ({{ node.account }})
                </button>
                <span
                  class="label"
                  v-bind:class="{
                    'label-primary': node.position <= 21,
                    'label-default': node.position > 21,
                  }"
                  >{{ node.position <= 21 ? 'Top21' : 'Standby' }}</span
                >

                <span
                  v-tooltip.top="
                    'This BP provides v1 history (full or light) or hyperion'
                  "
                  v-if="node.history || node.hyperion"
                  slot="reference"
                  class="label label-success ml-1"
                >
                  <i class="tim-icons icon-istanbul"></i>
                </span>

                <span
                  v-tooltip.top="'This BP uses patroneos on its API nodes'"
                  v-if="node.patroneos"
                  slot="reference"
                  class="label label-success ml-1"
                >
                  <i class="tim-icons icon-lock-circle"></i>
                </span>

                <span
                  v-tooltip.top="'This BP has warnings'"
                  v-if="node.warnings.length > 0"
                  slot="reference"
                  class="label label-warning ml-1"
                >
                  <i class="tim-icons icon-volume-98"></i>
                </span>
              </div>
            </div>
            <div
              class="card-body card-body-bp collapse"
              v-bind:id="'collapse-' + node.account"
              v-bind:data-parent="'#collapser-' + node.account"
            >
              <p>
                <span
                  v-tooltip.top="'bp.json'"
                  slot="reference"
                  class="label label-dark ml-1"
                >
                  <a target="_blank" :href="node.bp_json">
                    bp.json
                    <i class="tim-icons icon-link-72"></i>
                  </a>
                </span>
              </p>
              <br />
              <div v-if="node.errors.length > 0">
                <p>
                  <strong>Errors</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-error"
                    v-for="(error, index) in node.errors"
                    :key="node.account + '-error-' + index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div v-if="node.warnings.length > 0">
                <p>
                  <strong class="text-warning">Warnings</strong>
                </p>
                <ul class="warning-bullets">
                  <li
                    class="bullet-warning text-warning"
                    v-for="(warning, index) in node.warnings"
                    :key="node.account + '-warning-' + index"
                  >
                    {{ warning }}
                  </li>
                </ul>
              </div>
              <div v-if="node.oks.length > 0">
                <p>
                  <strong>Oks</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-ok"
                    v-for="(ok, index) in node.oks"
                    :key="node.account + '-ok-' + index"
                  >
                    {{ ok }}
                  </li>
                </ul>
              </div>
              <div
                v-for="(endpoint, index) in node.endpoints"
                :key="endpoint + '-ok-' + index"
              >
                <p>
                  <strong>{{ endpoint }}</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-ok"
                    v-for="(ok, endpoint) in node.endpoint_oks[endpoint]"
                    :key="endpoint + '-ok-' + index"
                  >
                    {{ ok }}
                  </li>
                  <li
                    class="bullet-error"
                    v-for="(error, endpoint) in node.endpoint_errors[endpoint]"
                    :key="endpoint + '-error-' + index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div
                v-for="(endpoint, index) in node.p2p_endpoints"
                :key="endpoint + '-ok-' + index"
              >
                <p>
                  <strong>P2P: {{ endpoint }}</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-ok"
                    v-for="(ok, endpoint) in node.endpoint_oks[endpoint]"
                    :key="endpoint + '-ok-' + index"
                  >
                    {{ ok }}
                  </li>
                  <li
                    class="bullet-error"
                    v-for="(error, endpoint) in node.endpoint_errors[endpoint]"
                    :key="endpoint + '-error-' + index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="card card">
          <!---->
          <div class="card-header">
            <h5 class="card-category text-danger">Errors</h5>
            <h3 class="card-title">
              <i class="tim-icons icon-settings text-danger"></i>
              {{ filterNodes(2).length }}
            </h3>
          </div>
          <div
            class="card card-bp"
            v-for="node in filterNodes(2)"
            :key="node.account"
          >
            <div class="card-header card-header-bp">
              <div class="card-title card-title-bp">
                <i class="tim-icons icon-button-power text-danger"></i>
                <button
                  v-if="$store.state.selectedNetwork == 'fio'"
                  type="button"
                  v-bind:id="'collapser-' + node.account"
                  class="btn btn-light btn-link title btn-bp"
                  data-toggle="collapse"
                  v-bind:data-target="'#collapse-' + node.account"
                  aria-expanded="false"
                >
                  {{ nameLimited(node.org_name) }} ({{ node['fio_address'] }})
                </button>
                <button
                  v-if="$store.state.selectedNetwork != 'fio'"
                  type="button"
                  v-bind:id="'collapser-' + node.account"
                  class="btn btn-light btn-link title btn-bp"
                  data-toggle="collapse"
                  v-bind:data-target="'#collapse-' + node.account"
                  aria-expanded="false"
                >
                  {{ nameLimited(node.org_name) }} ({{ node.account }})
                </button>
                <span
                  class="label"
                  v-bind:class="{
                    'label-primary': node.position <= 21,
                    'label-default': node.position > 21,
                  }"
                  >{{ node.position <= 21 ? 'Top21' : 'Standby' }}</span
                >

                <span
                  v-tooltip.top="'This BP provides v1 history'"
                  v-if="node.history"
                  slot="reference"
                  class="label label-success ml-1"
                >
                  <i class="tim-icons icon-istanbul"></i>
                </span>

                <span
                  v-tooltip.top="'This BP uses patroneos on its API nodes'"
                  v-if="node.patroneos"
                  slot="reference"
                  class="label label-success ml-1"
                >
                  <i class="tim-icons icon-lock-circle"></i>
                </span>
              </div>
            </div>
            <div
              class="card-body card-body-bp collapse"
              v-bind:id="'collapse-' + node.account"
              v-bind:data-parent="'#collapser-' + node.account"
            >
              <p>
                <span
                  v-tooltip.top="'bp.json'"
                  slot="reference"
                  class="label label-dark ml-1"
                >
                  <a target="_blank" :href="node.bp_json">
                    bp.json
                    <i class="tim-icons icon-link-72"></i>
                  </a>
                </span>
              </p>
              <br />
              <div v-if="node.errors.length > 0">
                <p>
                  <strong>Errors</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-error"
                    v-for="(error, index) in node.errors"
                    :key="node.account + '-error-' + index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div v-if="node.warnings.length > 0">
                <p>
                  <strong class="text-warning">Warnings</strong>
                </p>
                <ul class="warning-bullets">
                  <li
                    class="bullet-warning text-warning"
                    v-for="(warning, index) in node.warnings"
                    :key="node.account + '-warning-' + index"
                  >
                    {{ warning }}
                  </li>
                </ul>
              </div>
              <div v-if="node.oks.length > 0">
                <p>
                  <strong>Oks</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-ok"
                    v-for="(ok, index) in node.oks"
                    :key="node.account + '-ok-' + index"
                  >
                    {{ ok }}
                  </li>
                </ul>
              </div>
              <div
                v-for="(endpoint, index) in node.endpoints"
                :key="endpoint + '-ok-' + index"
              >
                <p>
                  <strong>{{ endpoint }}</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-ok"
                    v-for="(ok, endpoint) in node.endpoint_oks[endpoint]"
                    :key="endpoint + '-ok-' + index"
                  >
                    {{ ok }}
                  </li>
                  <li
                    class="bullet-error"
                    v-for="(error, endpoint) in node.endpoint_errors[endpoint]"
                    :key="endpoint + '-error-' + index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
              <div
                v-for="(endpoint, index) in node.p2p_endpoints"
                :key="endpoint + '-ok-' + index"
              >
                <p>
                  <strong>P2P: {{ endpoint }}</strong>
                </p>
                <ul class="ok-bullets">
                  <li
                    class="bullet-ok"
                    v-for="(ok, endpoint) in node.endpoint_oks[endpoint]"
                    :key="endpoint + '-ok-' + index"
                  >
                    {{ ok }}
                  </li>
                  <li
                    class="bullet-error"
                    v-for="(error, endpoint) in node.endpoint_errors[endpoint]"
                    :key="endpoint + '-error-' + index"
                  >
                    {{ error }}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from 'sweet-modal-vue';
import VueSlider from 'vue-slider-component';
import 'vue-slider-component/theme/default.css';
import moment from 'moment';
export default {
  name: 'nodestatus',
  components: {
    SweetModal,
    SweetModalTab,
    VueSlider,
  },
  data: function () {
    let dates = Array(40)
      .fill()
      .map((_, i) =>
        moment()
          .subtract(i + 1, 'd')
          .format('YYYY-MM-DD')
      );
    dates.reverse().push('Latest');
    return {
      value: 'Latest',
      data: dates,
    };
  },
  methods: {
    openModal(id) {
      this.$refs[id].open();
    },
    onTMChange(tm_date) {
      this.$store.commit('setTMDate', {
        network: this.$store.state.selectedNetwork,
        tm_date,
      });
      this.$store
        .dispatch('getNodestatusData', this.$store.state.selectedNetwork)
        .catch((err) =>
          this.$store.commit('setError', 'Error obteniendo datos: ' + err)
        );
    },

    getP2PList() {
      return this.$store.state.networks[
        this.$store.state.selectedNetwork
      ].healthy_p2p_endpoints
        .map((elem) => `p2p-peer-address = ${elem}`)
        .join('\n');
    },
    getAPIList() {
      return this.$store.state.networks[
        this.$store.state.selectedNetwork
      ].healthy_api_endpoints.join('\n');
    },
    getHistoryList() {
      return this.$store.state.networks[this.$store.state.selectedNetwork]
        .healthy_history_endpoints
        ? this.$store.state.networks[
            this.$store.state.selectedNetwork
          ].healthy_history_endpoints.join('\n')
        : [];
    },
    getHyperionList() {
      return this.$store.state.networks[this.$store.state.selectedNetwork]
        .healthy_hyperion_endpoints
        ? this.$store.state.networks[
            this.$store.state.selectedNetwork
          ].healthy_hyperion_endpoints.join('\n')
        : [];
    },
    getAtomicList() {
      console.log('culo');
      return this.$store.state.networks[this.$store.state.selectedNetwork]
        .healthy_atomic_endpoints
        ? this.$store.state.networks[
            this.$store.state.selectedNetwork
          ].healthy_atomic_endpoints.join('\n')
        : [];
    },
    getIPFSList() {
      console.log(
        this.$store.state.networks[this.$store.state.selectedNetwork]
      );
      return this.$store.state.networks[this.$store.state.selectedNetwork]
        .healthy_ipfs_endpoints
        ? this.$store.state.networks[
            this.$store.state.selectedNetwork
          ].healthy_ipfs_endpoints.join('\n')
        : [];
    },
    selectNetwork(network) {
      //this.$store.commit("setNetwork", network);
      this.$router.push({
        name: 'nodestatus_symbol',
        params: { symbol: network },
      });
    },
    filterNodes(st) {
      function compare(a, b) {
        a.score = a.history + a.hyperion + a.patroneos;
        b.score = b.history + b.hyperion + b.patroneos;
        if (a.score > b.score) return -1;
        if (b.score < a.score) return 1;
        return 0;
      }

      if (!this.$store.state.networks[this.$store.state.selectedNetwork].data)
        return Array();
      return this.$store.state.networks[this.$store.state.selectedNetwork].data
        .filter((item) => item.status == st)
        .sort(compare);
    },
    nameLimited(name) {
      if (name.length > 16) {
        return name.substring(0, 16) + '...';
      } else {
        return name;
      }
    },
  },
  mounted() {
    if (this.$route.params.symbol)
      this.$store.commit('setNetwork', this.$route.params.symbol);
    Object.keys(this.$store.state.networks).forEach((network) => {
      this.$store
        .dispatch('getNodestatusData', network)
        .catch((err) =>
          this.$store.commit('setError', 'Error obteniendo datos: ' + err)
        );
      setInterval(
        function () {
          this.$store
            .dispatch('getNodestatusData', network)
            .catch((err) =>
              this.$store.commit('setError', 'Error obteniendo datos: ' + err)
            );
        }.bind(this),
        600000
      );
    });
  },
  watch: {
    $route(to, from) {
      if (to.name == 'nodestatus_symbol') {
        if (to.params.symbol) {
          this.$store.commit('setNetwork', to.params.symbol);
        }
      }
    },
  },
};
</script>

<style scoped>
ul.ok-bullets {
  list-style: none;
}

li.bullet-error::before {
  content: '\2022';
  color: #fd5d93;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

li.bullet-ok::before {
  content: '\2022';
  color: #00f2c3;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

li.bullet-warning::before {
  content: '\2022';
  color: #ff8d72;
  font-weight: bold;
  font-size: 15px;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.btn-bp {
  color: #e3e3e3;
}

.card-bp {
  margin-bottom: 0;
}

.card-header-bp {
  padding: 0px 15px;
}

.card-body-bp {
  padding: 0px 15px;
  font-size: 0.9em;
}

.card-title-bp {
  margin-bottom: 0px;
}

.hidden {
  display: none;
}

#tm-box {
  width: 300px;
}
</style>
