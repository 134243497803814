<template>
  <div>
    <div class="wrapper">
      <div data="vue" class="sidebar">
        <div id="style-3" class="sidebar-wrapper">
          <div class="logo ml-4">
            <a href="https://ledgerwise.io" class="simple-text logo-normal"
              >WAX TOOLS</a
            >
          </div>
          <ul class="nav">
            <router-link tag="li" to="/nodestatus/wax" active-class="active">
              <a href="/" class="nav-link">
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>WAX Guild Status</p>
              </a>
            </router-link>
            <li class>
              <a
                href="https://names.ledgerwise.io/"
                target="_blank"
                class="nav-link"
              >
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>WAX names</p>
              </a>
            </li>
          </ul>
          <br />
          <div class="logo ml-4">
            <a href="https://ledgerwise.io" class="simple-text logo-normal"
              >WAX-TESTNET TOOLS</a
            >
          </div>
          <ul class="nav">
            <router-link
              tag="li"
              to="/nodestatus/waxtestnet"
              active-class="active"
            >
              <a href="/" class="nav-link">
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>WAX testnet Node Status</p>
              </a>
            </router-link>
          </ul>
          <div class="logo ml-4">
            <a href="https://ledgerwise.io" class="simple-text logo-normal"
              >FIO TOOLS</a
            >
          </div>
          <ul class="nav">
            <router-link tag="li" to="/nodestatus/fio" active-class="active">
              <a href="/" class="nav-link">
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>FIO Node Status</p>
              </a>
            </router-link>
          </ul>
          <br />
          <div class="logo ml-4">
            <a href="https://ledgerwise.io" class="simple-text logo-normal"
              >PROTON TOOLS</a
            >
          </div>
          <ul class="nav">
            <router-link tag="li" to="/nodestatus/proton" active-class="active">
              <a href="/" class="nav-link">
                <i class="tim-icons icon-chart-pie-36"></i>
                <p>PROTON Node Status</p>
              </a>
            </router-link>
          </ul>
        </div>
      </div>
      <div data="vue" class="main-panel">
        <nav class="navbar navbar-expand-lg navbar-absolute navbar-transparent">
          <div class="container-fluid">
            <div class="navbar-wrapper">
              <div
                class="navbar-toggle d-inline"
                :class="{ toggled: $sidebar.showSidebar }"
              >
                <button
                  class="navbar-toggler"
                  type="button"
                  @click="toggleSidebar"
                  data-toggle="collapse"
                  data-target="#navigation"
                  aria-controls="navigation-index"
                  aria-label="Toggle navigation"
                >
                  <span class="navbar-toggler-bar bar1"></span>
                  <span class="navbar-toggler-bar bar2"></span>
                  <span class="navbar-toggler-bar bar3"></span>
                </button>
              </div>
              <a href="https://ledgerwise.io" class="navbar-brand metal-logo">
                <img width="120px" src="./assets/img/logo-alt.png" />
              </a>
            </div>
            <div class="collapse navbar-collapse show" style="display: none">
              <ul class="navbar-nav ml-auto"></ul>
            </div>
          </div>
        </nav>
        <div class="content">
          <keep-alive>
            <router-view />
          </keep-alive>
        </div>
        <footer class="footer">
          <div class="container-fluid">
            <ul class="nav">
              <li class="nav-item">
                <a href="https://ledgerwise.io" class="nav-link">LedgerWise</a>
              </li>
            </ul>
            <div class="copyright">
              © 2020 made with
              <i class="tim-icons icon-heart-2"></i> by
              <a href="https://ledgerwise.io" target="_blank" rel="noopener"
                >LedgerWise</a
              >
            </div>
          </div>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { CollapseTransition } from 'vue2-transitions';
export default {
  name: 'app',
  components: {
    CollapseTransition,
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: true,
      searchModalVisible: false,
      searchQuery: '',
    };
  },
  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    toggleNavOpen() {
      let root = document.getElementsByTagName('html')[0];
      root.classList.toggle('nav-open');
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
  },
  computed: {},
  mounted() {
    this.$watch('$sidebar.showSidebar', this.toggleNavOpen);
  },
};
</script>

<style>
@import './assets/css/app.css';
@import './assets/css/tooltip.css';

.tooltipped {
  border-bottom: 1px dotted;
}
@media only screen and (min-width: 961px) {
  .metal-logo {
    margin-top: 30px !important;
    margin-left: 40px !important;
  }
}
</style>
