<template>
  <div v-if="!isEmpty($store.getters.vtProducerExtraInfo($route.params.account_name))">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!---->
          <div class="card-header">
            <div class="row">
              <div class="col-sm-12 text-left well">
                <img
                  class="bp-logo"
                  v-if="!isEmpty($store.getters.vtProducerExtraInfo($route.params.account_name).org)"
                  :src="$store.getters.vtProducerExtraInfo($route.params.account_name).org.branding.logo_256"
                />
                <img class="bp-logo" v-else src="@/assets/img/sockpuppet.jpg" />

                <h2 class="bp-title ml-4 mt-4">
                  <span
                    v-if="!isEmpty($store.getters.vtProducerExtraInfo($route.params.account_name).org)"
                  >{{$store.getters.vtProducerExtraInfo($route.params.account_name).org.candidate_name}} ({{ $route.params.account_name }})</span>
                  <span v-else>{{ $route.params.account_name }}</span>
                  <h5
                    v-if="!isEmpty($store.getters.vtProducerExtraInfo($route.params.account_name).org)"
                    class="card-category"
                  >{{ $store.getters.vtProducerExtraInfo($route.params.account_name).org.location.name }} ({{ $store.getters.vtProducerExtraInfo($route.params.account_name).org.location.country }})</h5>
                  <div
                    v-if="!isEmpty($store.getters.vtProducerExtraInfo($route.params.account_name).org)"
                  >
                    <!-- Website -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.website"
                      :href="$store.getters.vtProducerExtraInfo($route.params.account_name).org.website"
                      target="_blank"
                    >
                      <font-awesome-icon icon="globe" size="lg" />
                    </a>
                    <!-- Telegram -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.telegram"
                      :href="'https://t.me/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.telegram"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'telegram']" size="lg" />
                    </a>
                    <!-- Twitter -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.twitter"
                      :href="'https://twitter.com/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.twitter"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'twitter']" size="lg" />
                    </a>
                    <!-- Facebook -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.facebook"
                      :href="'https://facebook.com/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.facebook"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'facebook']" size="lg" />
                    </a>
                    <!-- Youtube -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.youtube"
                      :href="'https://youtube.com/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.youtube"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'youtube']" size="lg" />
                    </a>
                    <!-- Reddit -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.reddit"
                      :href="'https://reddit.com/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.reddit"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'reddit']" size="lg" />
                    </a>
                    <!-- Keybase -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.keybase"
                      :href="'https://keybase.io/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.keybase"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'keybase']" size="lg" />
                    </a>
                    <!-- Github -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.github"
                      :href="'https://github.com/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.github"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'github']" size="lg" />
                    </a>
                    <!-- Wechat -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.wechat"
                      :href="'https://steemit.org/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.wechat"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'weixin']" size="lg" />
                    </a>
                    <!-- Steemit -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.steemit"
                      :href="'https://steemit.org/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.steemit"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'stripe-s']" size="lg" />
                    </a>
                    <!-- Medium -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.medium"
                      :href="'https://medium.com/'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.social.medium"
                      target="_blank"
                    >
                      <font-awesome-icon :icon="['fab', 'medium']" size="lg" />
                    </a>
                    <!-- Email -->
                    <a
                      class="social-link"
                      v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).org.email"
                      :href="'mailto://'+$store.getters.vtProducerExtraInfo($route.params.account_name).org.email"
                      target="_blank"
                    >
                      <font-awesome-icon icon="envelope" size="lg" />
                    </a>
                  </div>
                </h2>
              </div>
              <div class="col-sm-6"></div>
            </div>
          </div>
          <div class="card-body"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="row">
          <!-- Votes-->
          <div class="col-lg-6">
            <div class="card">
              <!---->
              <div class="card-header">
                <h5 class="card-category">Votes</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-heart-2 text-success"></i>
                  <span
                    v-if="getProducerRealVotes($store.getters.vtProducerExtraInfo($route.params.account_name).owner)"
                  >&nbsp;{{numberWithCommas(getProducerRealVotes($store.getters.vtProducerExtraInfo($route.params.account_name).owner))}}</span>
                  <span
                    v-else
                  >&nbsp;{{numberWithCommas($store.getters.vtProducerExtraInfo($route.params.account_name).real_votes)}}</span>
                </h3>
                <span
                  class="text-success vote-change"
                  v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).real_votes - get24hVotes($route.params.account_name) >0"
                >+{{numberWithCommas($store.getters.vtProducerExtraInfo($route.params.account_name).real_votes - get24hVotes($route.params.account_name))}}</span>
                <span
                  class="text-danger vote-change"
                  v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).real_votes - get24hVotes($route.params.account_name) < 0"
                >{{numberWithCommas($store.getters.vtProducerExtraInfo($route.params.account_name).real_votes - get24hVotes($route.params.account_name))}}</span>
                <span
                  class="vote-change"
                  v-if="$store.getters.vtProducerExtraInfo($route.params.account_name).real_votes - get24hVotes($route.params.account_name) == 0"
                >0</span>
              </div>
              <div class="card-body">
                <div
                  class="chart-area"
                  :v-if="historicVoteData($route.params.account_name).chartData"
                >
                  <line-chart
                    :chart-data="historicVoteData($route.params.account_name).chartData"
                    :extra-options="historicVoteData($route.params.account_name).extraOptions"
                    ref="historyVoteChart"
                    chart-id="history-vote-chart"
                    :height="150"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
          <!-- Votes pct-->
          <div class="col-lg-6">
            <div class="card">
              <!---->
              <div class="card-header">
                <h5 class="card-category">Votes %</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-chart-pie-36 text-primary"></i>
                  {{$store.getters.vtProducerExtraInfo($route.params.account_name).pct_votes}}%
                </h3>
              </div>
              <div class="card-body">
                <div
                  class="chart-area"
                  :v-if="historicPctVoteData($route.params.account_name).chartData"
                >
                  <line-chart
                    :chart-data="historicPctVoteData($route.params.account_name).chartData"
                    :extra-options="historicPctVoteData($route.params.account_name).extraOptions"
                    ref="historyPctVoteChart"
                    chart-id="history-pct-vote-chart"
                    :height="150"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Main voters -->
        <div class="card">
          <!---->
          <div class="card-header">
            <h5 class="card-category">
              <span
                class="tooltipped"
                v-tooltip.top="$store.getters.vtProducerExtraInfo($route.params.account_name).num_voters.proxies+' proxies - '+ $store.getters.vtProducerExtraInfo($route.params.account_name).num_voters.users + ' users'"
              >{{$store.getters.vtProducerExtraInfo($route.params.account_name).num_voters.total}} voters</span>
            </h5>
            <h3
              class="card-title"
            >Top {{$store.getters.vtProducerExtraInfo($route.params.account_name).top_voters.length}} voters</h3>
          </div>
          <div class="card-body">
            <div class="table-responsive">
              <table class="table tablesorter">
                <thead class="text-primary">
                  <tr>
                    <th></th>
                    <th>Account</th>
                    <th>Votes</th>
                    <th>Proxy</th>
                  </tr>
                </thead>
                <tbody class>
                  <tr
                    v-for="(voter, index) in $store.getters.vtProducerExtraInfo($route.params.account_name).top_voters"
                    :key="voter.owner"
                  >
                    <td>{{index+1}}</td>
                    <td v-if="voter.is_proxy == 1">
                      <router-link :to="'/voteauditor/proxy/'+voter.owner">{{voter.owner}}</router-link>
                    </td>
                    <td v-else>
                      <a
                        :href="'https://wax.bloks.io/account/'+voter.owner"
                        target="_blank"
                      >{{voter.owner}}</a>
                    </td>
                    <td>{{numberWithCommas(voter.real_votes)}}</td>
                    <td>
                      <font-awesome-icon v-if="voter.is_proxy == 1" icon="check-circle" size="lg" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Last votes</h4>
            <rotate-loader :loading="!$store.getters.vtStreamStarted"></rotate-loader>
          </div>
          <div class="card-body">
            <h5 class>Vote change</h5>
            <vote-filter
              :initialValues="this.$store.getters.vtVoteChangeFilter"
              :onChange="voteFilterChange"
            ></vote-filter>
          </div>
        </div>

        <span
          v-for="(action) in $store.getters.vtActions($route.params.account_name)"
          :key="action.trx_id"
        >
          <vote-box
            :actionData="action.data"
            :date="new Date(action.blockTime)"
            :trxId="action.trxId"
            :voteChange="action.voteChange"
            :currentProducer="$route.params.account_name"
          />
        </span>
        <rotate-loader :loading="$store.getters.vtLoadingTransactions"></rotate-loader>
        <h5
          v-if="!isEmpty($store.getters.vtOldestBlock)"
        >Data up to {{new Date($store.getters.vtOldestBlock.blockTime).toLocaleString()}} (block {{$store.getters.vtOldestBlock.blockNum}})</h5>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          v-if="!$store.getters.vtLoadingTransactions && $store.getters.vtStreamStarted"
          @click="loadMoreData"
        >Load more data</button>
      </div>
    </div>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import Picker from "@/components/Picker";
import VoteBox from "@/components/VoteBox";
import VoteFilter from "@/components/VoteFilter";
import { numberWithCommas, isEmpty } from "@/lib/misc";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
import RotateLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "producer",
  components: {
    SweetModal,
    SweetModalTab,
    Picker,
    VoteBox,
    LineChart,
    BarChart,
    RotateLoader,
    VoteFilter
  },
  methods: {
    numberWithCommas,
    isEmpty,
    voteFilterChange(values) {
      this.$store.dispatch("setVoteChangeFilter", values);
    },
    loadMoreData() {
      this.$store.dispatch("getMoreActions");
    },
    get24hVotes(producer) {
      let result = this.$store.getters.vtProducers24.filter(item => {
        return item.key == producer;
      });
      if (result.length == 0) return 0;
      return result[0].json.real_votes;
    },
    historicVoteData(bp) {
      return {
        extraOptions: chartConfigs.historicVoteChartOptions,
        chartData: {
          labels: this.$store.getters.vtProducerHistoricVote(bp).dates,
          datasets: [
            {
              label: "Votes",
              fill: true,
              borderColor: "#42b883",
              pointBackgroundColor: "#42b883",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#42b883",
              pointRadius: 4,
              data: this.$store.getters.vtProducerHistoricVote(bp).values
            }
          ]
        }
      };
    },
    historicPctVoteData(bp) {
      return {
        extraOptions: chartConfigs.historicPctVoteChartOptions,
        chartData: {
          labels: this.$store.getters.vtProducerHistoricVote(bp).dates,
          datasets: [
            {
              label: "Pct Votes",
              fill: true,
              borderColor: "#F92D81",
              pointBackgroundColor: "#F92D81",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#F92D81",
              pointRadius: 4,
              data: this.$store.getters.vtProducerHistoricVote(bp).pcts
            }
          ]
        }
      };
    },
    getProducerRealVotes(bpAccount) {
      let bpData = this.$store.getters.vtProducers.filter(
        item => item.key == bpAccount
      )[0];
      if (!bpData) return 0;
      return bpData.json.real_votes;
    },
    getHistoricVote(bpAccount) {},
    getBPInfo(bpAccount) {
      if (
        bpAccount &&
        isEmpty(this.$store.getters.vtProducerExtraInfo(bpAccount))
      ) {
        this.$store
          .dispatch("getVTProducerInfo", bpAccount)
          .then(this.$store.dispatch("getVTProducerHistoricVote", bpAccount))
          .catch(err =>
            this.$store.commit("setError", "Error obteniendo datos: " + err)
          );
      }
    }
  },
  updated() {
    this.getBPInfo(this.$route.params.account_name);
  },
  mounted() {
    if (!this.$store.getters.vtInitialized) {
      this.$store.dispatch("initVT");
    }
    this.getBPInfo(this.$route.params.account_name);
  }
};
</script>

<style scoped>
.hidden {
  display: none;
}
</style>

<style scoped>
.vote-hr {
  margin-top: 0px;
}
.vote-card-body {
  padding-bottom: 0px;
}
.bp-logo {
  width: 80px;
  height: 80px;
}
.bp-title {
  margin: 0 px;
}
.well {
  display: flex;
  align-items: center;
}
.social-link {
  font-size: 13px;
  margin-left: 5px;
  color: #ba54f5;
}

.card-title {
  font-size: 20px;
}
.vote-change {
  font-size: 0.7rem;
}
.card-category {
  font-size: 0.95rem;
}

.badge-vote {
  font-size: 0.8rem;
  padding: 0.5rem 0.5rem;
}

.badge-success {
  background-color: #349a63 !important;
}
</style>

