import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import { isEmpty, realVotes } from '@/lib/misc';
import { Api, JsonRpc } from 'eosjs';
import { JsSignatureProvider } from 'eosjs/dist/eosjs-jssig';
import {
  getTypesFromAbi,
  createInitialTypes,
  hexToUint8Array,
  SerialBuffer,
} from 'eosjs/dist/eosjs-serialize';
import VuexPersistence from 'vuex-persist';

Vue.use(Vuex);

const { createDfuseClient, InboundMessageType } = require('@dfuse/client');
const dfuseKEY = 'web_57d5b978654779b571cdb054a2729e4a';

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    voteauditor: {
      voteChangeFilter: state.voteauditor.voteChangeFilter,
    },
  }),
  //filter: (mutation) => mutation.type == 'addNavItem'
});

export default new Vuex.Store({
  plugins: [vuexLocal.plugin],
  state: {
    networks: {
      wax: {
        name: 'WAX',
        chain_id:
          '1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4',
        data: [],
        healthy_api_endpoints: [],
        healthy_p2p_endpoints: [],
        last_update: '',
        tm_date: 'Latest',
      },
      waxtestnet: {
        name: 'WAX testnet',
        chain_id:
          'f16b1833c747c43682f4386fca9cbb327929334a762755ebec17f6f23c9b8a12',
        data: [],
        healthy_api_endpoints: [],
        healthy_p2p_endpoints: [],
        last_update: '',
        tm_date: 'Latest',
      },
      fio: {
        name: 'FIO',
        chain_id:
          '21dcae42c0182200e93f954a074011f9048a7624c6fe81d3c9541a614a88bd1c',
        data: [],
        healthy_api_endpoints: [],
        healthy_p2p_endpoints: [],
        last_update: '',
        tm_date: 'Latest',
      },
      proton: {
        name: 'PROTON',
        chain_id:
          '384da888112027f0321850a169f737c33e53b388aad48b5adace4bab97f437e0',
        data: [],
        healthy_api_endpoints: [],
        healthy_p2p_endpoints: [],
        last_update: '',
        tm_date: 'Latest',
      },
    },
    err: '',
    selectedNetwork: 'wax',
    snapshots: {
      networks: {
        wax: [],
        worbli: [],
        'telos-testnet': [],
        'worbli-testnet': [],
      },
    },
    voteauditor: {
      selectedNetwork: 'WAX',
      voteChangeFilter: [1, 1000000000],
      selectedTab: 'guilds',
      tabs: ['guilds', 'proxies', 'stats'],
      networks: {
        WAX: {
          initialized: false,
          dfuseEndpoint: 'https://mainnet.wax.dfuse.io',
          dfuseClient: createDfuseClient({
            apiKey: dfuseKEY,
            network: 'mainnet.wax.dfuse.io',
          }),
          streamStarted: false,
          global: {},
          history: {},
          stats: {},
          loadingTransactions: false,
          EOSIOAbi: null,
          headBlock: null,
          producers: [],
          producers24: [],
          producerExtraInfo: {},
          producerHistoricVote: {},
          proxies: [],
          proxyExtraInfo: {},
          proxyHistoricVote: {},
          actions: {
            all: [],
          },
          oldestBlock: {},
        },
      },
    },
  },
  mutations: {
    setNodestatusData: (state, payload) => {
      state.networks[payload.network].data = payload.data.producers;
      state.networks[payload.network].healthy_api_endpoints =
        payload.data.healthy_api_endpoints;
      state.networks[payload.network].healthy_p2p_endpoints =
        payload.data.healthy_p2p_endpoints;
      state.networks[payload.network].healthy_history_endpoints =
        payload.data.healthy_history_endpoints;
      state.networks[payload.network].healthy_hyperion_endpoints =
        payload.data.healthy_hyperion_endpoints;
      state.networks[payload.network].healthy_atomic_endpoints =
        payload.data.healthy_atomic_endpoints;
      state.networks[payload.network].healthy_ipfs_endpoints =
        payload.data.healthy_ipfs_endpoints;
      state.networks[payload.network].last_update = payload.data.last_update;
    },
    setTMDate: (state, payload) => {
      console.log(payload);
      state.networks[payload.network].tm_date = payload.tm_date;
    },
    setSnaprepoData: (state, payload) => {
      Object.keys(payload.data.networks).forEach((network) => {
        payload.data.networks[network] = payload.data.networks[network].sort(
          (a, b) => {
            if (a.name > b.name) {
              return -1;
            }
            if (a.name < b.name) {
              return 1;
            }
            return 0;
          }
        );
      });
      state.snapshots = payload.data;
    },
    setError: (state, err) => {
      state.err = err;
    },
    setNetwork: (state, network) => {
      state.selectedNetwork = network;
    },
    setLoadingTransactions: (state, loading) => {
      state.voteauditor.networks[
        state.voteauditor.selectedNetwork
      ].loadingTransactions = loading;
    },
    setStreamStarted: (state) => {
      state.voteauditor.networks[
        state.voteauditor.selectedNetwork
      ].streamStarted = true;
    },
    setHeadBlock: (state, block) => {
      state.voteauditor.networks[state.voteauditor.selectedNetwork].headBlock =
        block;
    },
    setProducers: (state, producers) => {
      producers.sort((a, b) =>
        a.json.total_votes < b.json.total_votes
          ? 1
          : b.json.total_votes < a.json.total_votes
          ? -1
          : 0
      );
      state.voteauditor.networks[state.voteauditor.selectedNetwork].producers =
        producers;
      producers.forEach((producer) => {
        Vue.set(
          state.voteauditor.networks[state.voteauditor.selectedNetwork]
            .producerExtraInfo,
          producer.key,
          {}
        );
      });
    },
    setProducers24: (state, producers) => {
      producers.sort((a, b) =>
        a.json.total_votes < b.json.total_votes
          ? 1
          : b.json.total_votes < a.json.total_votes
          ? -1
          : 0
      );
      state.voteauditor.networks[
        state.voteauditor.selectedNetwork
      ].producers24 = producers;
    },
    setProducerExtraInfo: (state, info) => {
      Vue.set(
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .producerExtraInfo,
        info.bp,
        info.data
      );
    },
    setProducerHistoricVote: (state, info) => {
      Vue.set(
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .producerHistoricVote,
        info.bp,
        info.data
      );
    },
    setProxies: (state, proxies) => {
      state.voteauditor.networks[state.voteauditor.selectedNetwork].proxies =
        proxies;
      proxies.forEach((proxy) => {
        Vue.set(
          state.voteauditor.networks[state.voteauditor.selectedNetwork]
            .proxyExtraInfo,
          proxy.owner,
          {}
        );
      });
    },
    setProxyExtraInfo: (state, info) => {
      Vue.set(
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .proxyExtraInfo,
        info.proxy,
        info.data
      );
    },
    setProxyHistoricVote: (state, info) => {
      Vue.set(
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .proxyHistoricVote,
        info.proxy,
        info.data
      );
    },
    setGlobal: (state, data) => {
      state.voteauditor.networks[state.voteauditor.selectedNetwork].global =
        data.rows[0].json;
    },
    setHistory: (state, history) => {
      Vue.set(
        state.voteauditor.networks[state.voteauditor.selectedNetwork],
        'history',
        history
      );
    },
    setStats: (state, stats) => {
      Vue.set(
        state.voteauditor.networks[state.voteauditor.selectedNetwork],
        'stats',
        stats
      );
    },
    setVoteChangeFilter: (state, filter) => {
      Vue.set(state.voteauditor, 'voteChangeFilter', filter);
    },
    setOldestBlock: (state, data) => {
      state.voteauditor.networks[
        state.voteauditor.selectedNetwork
      ].oldestBlock = data;
    },

    setEOSIOAbi: (state, abi) => {
      state.voteauditor.networks[state.voteauditor.selectedNetwork].EOSIOAbi =
        abi;
    },

    saveAction: (state, data) => {
      const builtinTypes = createInitialTypes();
      const types = getTypesFromAbi(
        builtinTypes,
        state.voteauditor.networks[state.voteauditor.selectedNetwork].EOSIOAbi
      );
      const producerInfoType = types.get('producer_info');
      const voterInfoType = types.get('voter_info');
      let action = data.action;
      let act_data = action.act.data;
      let trx_id = action.trx_id;
      let dbops = data.dbops;
      let array_op = data.array_op;

      //Fix UTC time
      if (action.block_time[action.block_time - 1] != 'Z') {
        action.block_time += 'Z';
      }

      let result = {
        data: act_data,
        name: action.act.name,
        voteChange: 0,
        blockTime: action.block_time,
        trxId: trx_id,
      };

      if (action.act.name == 'voteproducer') {
        let proxy = act_data.proxy;
        let all_actions =
          state.voteauditor.networks[state.voteauditor.selectedNetwork].actions
            .all;
        //if (all_actions.findIndex((item) => item.trxId === trx_id) == -1) {
        if (array_op == 'push') {
          state.voteauditor.networks[
            state.voteauditor.selectedNetwork
          ].actions.all.push(result);
        } else if (array_op == 'unshift') {
          state.voteauditor.networks[
            state.voteauditor.selectedNetwork
          ].actions.all.unshift(result);
        } else return;
        //}

        dbops.forEach((op) => {
          //Change in vote
          let opTable = '';
          let producer = '';
          let newHex = '';
          let oldHex = '';

          if (typeof op.path != 'undefined') {
            var rx = /.*\/.*\/(.*)\/(.*)/g;
            var arr = rx.exec(op.path);
            opTable = arr[1];
            producer = arr[2];
            newHex = op.new;
            oldHex = op.old;
          } else if (typeof op.table != 'undefined') {
            opTable = op.table;
            producer = op.key;
            newHex = op.new.hex;
            oldHex = op.old.hex;
          }

          if (opTable == 'producers' || opTable == 'voters') {
            const buffer = new SerialBuffer({
              textDecoder: new TextDecoder(),
              textEncoder: new TextEncoder(),
            });

            let hexData = op.old;
            let data = hexToUint8Array(oldHex);
            buffer.pushArray(data);

            hexData = op.new;
            data = hexToUint8Array(newHex);
            buffer.pushArray(data);

            //Producers
            if (opTable == 'producers') {
              let oldData = producerInfoType.deserialize(buffer);
              let newData = producerInfoType.deserialize(buffer);
              let newResult = {
                ...result,
              };
              newResult.voteChange = parseInt(
                realVotes(newData.total_votes) - realVotes(oldData.total_votes)
              );
              if (newResult.voteChange != 0) {
                if (
                  !state.voteauditor.networks[state.voteauditor.selectedNetwork]
                    .actions[producer]
                )
                  Vue.set(
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions,
                    producer,
                    []
                  );
                let bp_actions =
                  state.voteauditor.networks[state.voteauditor.selectedNetwork]
                    .actions[producer];
                let actionIndex = bp_actions.findIndex(
                  (item) => item.trxId === trx_id
                );
                if (actionIndex == -1) {
                  if (array_op == 'push') {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[producer].push(newResult);
                  } else if (array_op == 'unshift') {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[producer].unshift(newResult);
                  }
                } else {
                  newResult.voteChange +=
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[producer][actionIndex].voteChange;
                  if (newResult.voteChange != 0) {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[producer][actionIndex] = newResult;
                  } else {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[producer].splice(actionIndex, 1);
                  }
                }
              }
            }
            // Proxies
            else if (opTable == 'voters') {
              let oldData = voterInfoType.deserialize(buffer);
              let newData = voterInfoType.deserialize(buffer);

              let newResult = {
                ...result,
              };
              newResult.voteChange = parseInt(
                realVotes(newData.last_vote_weight) -
                  realVotes(oldData.last_vote_weight)
              );
              if (newResult.voteChange != 0) {
                if (
                  !state.voteauditor.networks[state.voteauditor.selectedNetwork]
                    .actions[proxy]
                )
                  state.voteauditor.networks[
                    state.voteauditor.selectedNetwork
                  ].actions[proxy] = [];
                let proxy_actions =
                  state.voteauditor.networks[state.voteauditor.selectedNetwork]
                    .actions[proxy];
                let actionIndex = proxy_actions.findIndex(
                  (item) => item.trxId === trx_id
                );
                if (actionIndex == -1) {
                  if (array_op == 'push') {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[proxy].push(newResult);
                  } else if (array_op == 'unshift') {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[proxy].unshift(newResult);
                  }
                } else {
                  newResult.voteChange +=
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[proxy][actionIndex].voteChange;
                  if (newResult.voteChange != 0) {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[proxy][actionIndex] = newResult;
                  } else {
                    state.voteauditor.networks[
                      state.voteauditor.selectedNetwork
                    ].actions[proxy].splice(actionIndex, 1);
                  }
                }
              }
            }
          }
        });
      }
    },
    setInitVT: (state) => {
      state.voteauditor.networks[
        state.voteauditor.selectedNetwork
      ].initialized = true;
    },
    setVTSelectedTab: (state, data) => {
      state.voteauditor.selectedTab = data;
    },
  },
  actions: {
    setLoadingTransactions({ commit }, loading) {
      commit('setLoadingTransactions', loading);
    },

    setVoteChangeFilter({ commit }, filter, loading) {
      commit('setVoteChangeFilter', filter);
    },

    async getNodestatusData({ commit }, payload) {
      let chain_id = this.state.networks[payload].chain_id;
      let tm_date = this.state.networks[payload].tm_date;
      let url = `https://api.ledgerwise.io/apps/nodestatus/${chain_id}.json`;
      if (tm_date != 'Latest') {
        url = `https://api.ledgerwise.io/apps/nodestatus/${chain_id}-${tm_date}.json`;
      }
      const response = await axios.get(url);
      return commit('setNodestatusData', {
        data: response.data,
        network: payload,
      });
    },

    async getSnaprepoData({ commit }, payload) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/snaprepo/snaprepo.json`
      );
      return commit('setSnaprepoData', {
        data: response.data,
      });
    },

    async getVTGlobal({ commit, state }, payload) {
      const client =
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .dfuseClient;
      try {
        const response = await client.stateTable('eosio', 'eosio', 'global');
        return commit('setGlobal', response);
      } catch (error) {
        console.log('An error occurred', error);
      }
    },

    async getVTProducers({ commit, dispatch, state, getters }, payload) {
      const client =
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .dfuseClient;
      try {
        const response = await client.stateTable('eosio', 'eosio', 'producers');
        commit('setHeadBlock', response.up_to_block_num);
        response.rows.forEach((producer) => {
          producer.json.real_votes = parseInt(
            producer.json.total_votes / getters.voteWeight / 100000000
          );
        });
        let producers = response.rows.filter((row) => row.json.is_active);
        dispatch('getVTProducers24');
        return commit('setProducers', producers);
      } catch (error) {
        console.log('An error occurred', error);
      }
    },

    async getVTProducers24({ commit, state, getters }, payload) {
      const client =
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .dfuseClient;
      try {
        const response = await client.stateTable(
          'eosio',
          'eosio',
          'producers',
          {
            blockNum:
              state.voteauditor.networks[state.voteauditor.selectedNetwork]
                .headBlock - 172800,
          }
        );
        response.rows.forEach((producer) => {
          producer.json.real_votes = parseInt(
            producer.json.total_votes / getters.voteWeight / 100000000
          );
        });
        return commit('setProducers24', response.rows);
      } catch (error) {
        console.log('An error occurred', error);
      }
    },

    async getVTProducerInfo({ commit }, payload) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/voteauditor/bps/${payload}.json`
      );
      return commit('setProducerExtraInfo', {
        data: response.data,
        bp: payload,
      });
    },

    async getVTProducerHistoricVote({ commit }, payload) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/voteauditor/bps/${payload}_vote_history.json`
      );
      return commit('setProducerHistoricVote', {
        data: response.data,
        bp: payload,
      });
    },

    async getVTProxies({ commit, state, getters }) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/voteauditor/proxies.json`
      );
      return commit('setProxies', response.data);
    },

    async getVTProxyHistoricVote({ commit }, payload) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/voteauditor/proxies/${payload}_history.json`
      );
      return commit('setProxyHistoricVote', {
        data: response.data,
        proxy: payload,
      });
    },

    async getVTProxyInfo({ commit }, payload) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/voteauditor/proxies/${payload}.json`
      );
      return commit('setProxyExtraInfo', {
        data: response.data,
        proxy: payload,
      });
    },

    async getVTHistory({ commit, state, getters }) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/voteauditor/history.json`
      );
      return commit('setHistory', response.data);
    },

    async getVTStats({ commit, state, getters }) {
      const response = await axios.get(
        `https://api.ledgerwise.io/apps/voteauditor/stats.json`
      );
      return commit('setStats', response.data);
    },

    async initVT({ commit, dispatch, getters }) {
      commit('setInitVT');

      const signatureProvider = new JsSignatureProvider([]);
      const rpc = new JsonRpc('https://eos.greymass.com');
      const api = new Api({
        rpc,
        signatureProvider,
      });

      const abi = await api.getAbi('eosio');

      commit('setEOSIOAbi', abi);

      dispatch('getVTGlobal')
        .then(dispatch('getVTProducers'))
        .then(dispatch('getVTProxies'))
        .then(dispatch('getVTHistory'))
        .then(dispatch('getVTStats'))
        .catch((err) => {
          commit('setError', 'Error getting global table: ' + err);
          console.log(err);
        });

      let client = getters.vtDfuseClient;
      let blocksStreamed = 0;
      client.streamActionTraces(
        {
          accounts: 'eosio',
          action_name: 'voteproducer',
          with_dbops: true,
        },
        (message) => {
          if (message.type !== 'action_trace') {
            console.log(message);
            return;
          }

          if (blocksStreamed == 0) {
            commit('setOldestBlock', {
              blockNum: message.data.block_num,
              blockTime: message.data.block_time,
            });
            dispatch('getMoreActions');
          }
          blocksStreamed++;
          commit('saveAction', {
            action: message.data.trace,
            dbops: message.data.dbops,
            array_op: 'unshift',
          });
        },
        {
          start_block: -3600,
        }
      );

      setInterval(() => {
        dispatch('getVTProducers')
          .then(dispatch('getVTProxies'))
          .then(dispatch('getVTHistory'))
          .then(dispatch('getVTStats'));
      }, 5 * 60 * 1000); //Every 5m
    },

    async getMoreActions({ commit, dispatch, getters }) {
      if (isEmpty(getters.vtOldestBlock) || getters.vtLoadingTransactions)
        return;
      commit('setLoadingTransactions', true);
      commit('setStreamStarted', true);
      let client = getters.vtDfuseClient;
      let blockPeriod = 6 * 60 * 60 * 2; // 6h
      let toBlock = getters.vtOldestBlock.blockNum - 1;
      let fromBlock = toBlock - blockPeriod;
      let transactions = Array();

      try {
        while (true) {
          const response = await client.searchTransactions(
            'account:eosio action:voteproducer',
            {
              startBlock: fromBlock,
              order: 'desc',
              blockCount: blockPeriod,
              cursor: response ? response.cursor : '',
            }
          );

          transactions.push(...response.transactions);

          if (!response.cursor) {
            commit('setLoadingTransactions', false);

            if (transactions.length > 0) {
              commit('setOldestBlock', {
                blockNum: transactions[0].lifecycle.execution_trace.block_num,
                blockTime:
                  transactions[0].lifecycle.execution_trace.block_time + 'Z',
              });
            }

            transactions.reverse();
            transactions.forEach((transaction) => {
              commit('saveAction', {
                action: transaction.lifecycle.execution_trace.action_traces[0],
                dbops: transaction.lifecycle.dbops,
                array_op: 'push',
              });
            });
            break;
          }
        }
      } catch (error) {
        console.log('An error occurred', error);
      }
    },
  },
  getters: {
    vtProducers: (state) => {
      return state.voteauditor.networks[
        state.voteauditor.selectedNetwork
      ].producers.slice(0, 300);
    },
    vtProducers24: (state) => {
      return state.voteauditor.networks[
        state.voteauditor.selectedNetwork
      ].producers24.slice(0, 300);
    },
    vtProxies: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .proxies;
    },
    vtActions: (state) => (selector) => {
      let voteChangeFilter = state.voteauditor.voteChangeFilter;
      let actions =
        state.voteauditor.networks[state.voteauditor.selectedNetwork].actions[
          selector
        ];
      if (selector == 'all') return actions;
      if (voteChangeFilter && actions) {
        return state.voteauditor.networks[
          state.voteauditor.selectedNetwork
        ].actions[selector].filter(
          (action) =>
            Math.abs(action.voteChange) >= voteChangeFilter[0] &&
            Math.abs(action.voteChange) <= voteChangeFilter[1]
        );
      } else return actions;
    },
    vtDfuseClient: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .dfuseClient;
    },
    vtGlobal: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .global;
    },
    vtStats: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .stats;
    },
    vtOldestBlock: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .oldestBlock;
    },
    vtInitialized: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .initialized;
    },
    vtVoteChangeFilter: (state) => {
      return state.voteauditor.voteChangeFilter;
    },
    vtProducerExtraInfo: (state) => (selector) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .producerExtraInfo[selector];
    },
    vtProducerHistoricVote: (state) => (selector) => {
      let data =
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .producerHistoricVote[selector];
      if (!data) return [];
      return {
        values: data.map((x) => x.real_votes),
        pcts: data.map((x) => x.pct_votes),
        dates: data.map((x) => new Date(x.date).toLocaleDateString()),
      };
    },

    vtProxyExtraInfo: (state) => (selector) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .proxyExtraInfo[selector];
    },

    vtProxyHistoricVote: (state) => (proxy, selector) => {
      let data =
        state.voteauditor.networks[state.voteauditor.selectedNetwork]
          .proxyHistoricVote[proxy];
      if (!data) return false;
      if (selector == 'votes') {
        let result = {
          values: Object.keys(data).map((x) => data[x].num_votes),
          dates: Object.keys(data).map((x) =>
            new Date(data[x].date).toLocaleDateString()
          ),
        };
        result.values.push(
          state.voteauditor.networks[state.voteauditor.selectedNetwork]
            .proxyExtraInfo[proxy].real_votes
        );
        result.dates.push(new Date().toLocaleDateString());
        return result;
      } else if (selector == 'voters') {
        let result = {
          values: Object.keys(data).map((x) => data[x].num_voters),
          dates: Object.keys(data).map((x) =>
            new Date(data[x].date).toLocaleDateString()
          ),
        };
        result.values.push(
          state.voteauditor.networks[state.voteauditor.selectedNetwork]
            .proxyExtraInfo[proxy].num_voters
        );
        result.dates.push(new Date().toLocaleDateString());
        return result;
      } else return false;
    },

    vtHistory: (state) => (selector) => {
      let data =
        state.voteauditor.networks[state.voteauditor.selectedNetwork].history;
      let stats =
        state.voteauditor.networks[state.voteauditor.selectedNetwork].stats;
      if (!data) return false;
      if (selector == 'proxy_votes') {
        let result = {
          values: Object.keys(data).map((x) => data[x].proxy_votes),
          dates: Object.keys(data).map((x) =>
            new Date(data[x].date).toLocaleDateString()
          ),
        };
        result.values.push(stats.proxy_votes);
        result.dates.push(new Date().toLocaleDateString());
        return result;
      } else if (selector == 'direct_votes') {
        let result = {
          values: Object.keys(data).map((x) => data[x].direct_votes),
          dates: Object.keys(data).map((x) =>
            new Date(data[x].date).toLocaleDateString()
          ),
        };
        result.values.push(stats.direct_votes);
        result.dates.push(new Date().toLocaleDateString());
        return result;
      } else if (selector == 'total_votes') {
        let result = {
          values: Object.keys(data).map(
            (x) => data[x].proxy_votes + data[x].direct_votes
          ),
          dates: Object.keys(data).map((x) =>
            new Date(data[x].date).toLocaleDateString()
          ),
        };
        result.values.push(stats.proxy_votes + stats.direct_votes);
        result.dates.push(new Date().toLocaleDateString());
        return result;
      } else if (selector == 'num_voters') {
        let result = {
          values: Object.keys(data).map((x) => data[x].num_voters),
          dates: Object.keys(data).map((x) =>
            new Date(data[x].date).toLocaleDateString()
          ),
        };
        result.values.push(stats.num_voters);
        result.dates.push(new Date().toLocaleDateString());
        return result;
      } else return false;
    },

    voteWeight: (state) => {
      return 3096195506451864088927197840640.81461248 / 490442;
      const timestampEpoch = 946684800000;
      var dates_ = Date.now() / 1000 - timestampEpoch / 1000;
      var weight_ = parseInt(dates_ / (86400 * 7)) / 52; //86400 = seconds per day 24*3600
      return Math.pow(2, weight_);
      return;
    },
    vtLoadingTransactions: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .loadingTransactions;
    },
    vtStreamStarted: (state) => {
      return state.voteauditor.networks[state.voteauditor.selectedNetwork]
        .streamStarted;
    },

    snapshots: (state) => (selector) => {
      return state.snapshots.networks[selector];
    },
  },
});
