<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <!---->
          <div class="card-header">
            <div class="row">
              <div class="col-sm-6 text-left">
                <img src="@/assets/img/vote-auditor-logo.png" class="mb-2" />
                <!-- <h2
                  class="card-title"
                >{{$store.state.voteauditor.selectedNetwork.toUpperCase()}} Vote Auditor</h2>-->
              </div>
              <div class="col-sm-6"></div>
            </div>
          </div>
          <div class="card-body"></div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <!---->
          <div class="card-header">
            <div class="row">
              <div class="col-lg-8">
                <h4 class="card-title">{{$store.state.voteauditor.selectedTab.toUpperCase()}}</h4>
              </div>
              <div class="col-lg-4">
                <picker
                  :values="$store.state.voteauditor.tabs"
                  :onSelect="selectTab"
                  :active="$store.state.voteauditor.selectedTab"
                ></picker>
              </div>
            </div>
          </div>

          <!-- Producers  -->
          <div class="card-body" v-if="$store.state.voteauditor.selectedTab == 'guilds'">
            <!-- Producer Filter -->
            <div class="form-group">
              <label class="control-label">Filter</label>
              <input
                aria-describedby="addon-right addon-left"
                class="form-control"
                v-model.trim="producer_filter"
              />
            </div>

            <!-- Producer table -->
            <div class="table-responsive">
              <table class="table tablesorter">
                <thead class="text-primary">
                  <tr>
                    <th></th>
                    <th>Name</th>
                    <th>Votes</th>
                    <th>24h change</th>
                  </tr>
                </thead>
                <tbody class>
                  <tr v-for="(producer, index) in getProducers()" :key="producer.key">
                    <td>{{index+1}}</td>
                    <td>
                      <router-link :to="'/voteauditor/producer/'+producer.key">{{producer.key}}</router-link>
                    </td>
                    <td>{{numberWithCommas(producer.json.real_votes)}}</td>
                    <td>
                      <span
                        class="text-success vote-change"
                        v-if="producer.json.real_votes - get24hVotes(producer.key) >0"
                      >+{{numberWithCommas(producer.json.real_votes - get24hVotes(producer.key))}}</span>
                      <span
                        class="text-danger vote-change"
                        v-if="producer.json.real_votes - get24hVotes(producer.key) < 0"
                      >{{numberWithCommas(producer.json.real_votes - get24hVotes(producer.key))}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="pull-right">
              <h5 class="powered">Powered by &nbsp;</h5>
              <a href="https://dfuse.io">
                <img
                  width="60"
                  src="https://www.dfuse.io/hubfs/dfuse-website/dfuse-logo2.svg"
                  alt="dfuse"
                />
              </a>
            </div>
          </div>

          <!-- Proxies  -->
          <div class="card-body" v-if="$store.state.voteauditor.selectedTab == 'proxies'">
            <!-- Proxies Filter -->
            <div class="form-group">
              <label class="control-label">Filter</label>
              <input
                aria-describedby="addon-right addon-left"
                class="form-control"
                v-model.trim="proxy_filter"
              />
            </div>

            <!-- Proxies table -->
            <div class="table-responsive">
              <table class="table tablesorter">
                <thead class="text-primary">
                  <tr>
                    <th></th>
                    <th>Account</th>
                    <th>Votes</th>
                    <th>24h change</th>
                  </tr>
                </thead>
                <tbody class>
                  <tr v-for="(proxy, index) in getProxies()" :key="proxy.owner">
                    <td>{{index+1}}</td>
                    <td>
                      <router-link :to="'/voteauditor/proxy/'+proxy.owner">{{proxy.owner}}</router-link>
                    </td>
                    <td>{{numberWithCommas(proxy.real_votes)}}</td>
                    <td>
                      <span
                        class="text-success vote-change"
                        v-if="proxy.change_24 >0"
                      >+{{numberWithCommas(proxy.change_24 )}}</span>
                      <span
                        class="text-danger vote-change"
                        v-if="proxy.change_24  < 0"
                      >{{numberWithCommas(proxy.change_24 )}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- Stats -->
        <div class="row" v-if="$store.state.voteauditor.selectedTab == 'stats'">
          <div class="col-lg-6">
            <div class="card">
              <!---->
              <div class="card-header">
                <h5 class="card-category">Votes</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-chart-pie-36 text-primary"></i>
                  {{numberWithCommas($store.getters.vtStats.direct_votes + $store.getters.vtStats.proxy_votes)}}
                </h3>
              </div>
              <div class="card-body">
                <div class="chart-area" :v-if="historicVoteData().chartData">
                  <line-chart
                    :chart-data="historicVoteData().chartData"
                    :extra-options="historicVoteData().extraOptions"
                    ref="historyVoteChart"
                    chart-id="history-vote-chart"
                    :height="200"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-6">
            <div class="card" v-if="$store.state.voteauditor.selectedTab == 'stats'">
              <!---->
              <div class="card-header">
                <h5 class="card-category">Voters</h5>
                <h3 class="card-title">
                  <i class="tim-icons icon-chart-pie-36 text-primary"></i>
                  {{numberWithCommas($store.getters.vtStats.num_voters)}}
                </h3>
              </div>
              <div class="card-body">
                <div class="chart-area" :v-if="historicVoterData().chartData">
                  <line-chart
                    :chart-data="historicVoterData().chartData"
                    :extra-options="historicVoterData().extraOptions"
                    ref="historyVoterChart"
                    chart-id="history-voter-chart"
                    :height="200"
                  ></line-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <h4 class="card-title">Last votes</h4>
        <rotate-loader :loading="!$store.getters.vtStreamStarted"></rotate-loader>
        <span v-for="(action) in $store.getters.vtActions('all')" :key="action.trx_id">
          <vote-box
            :actionData="action.data"
            :date="new Date(action.blockTime)"
            :trxId="action.trxId"
          />
        </span>
        <rotate-loader :loading="$store.getters.vtLoadingTransactions"></rotate-loader>
        <h5
          v-if="!isEmpty($store.getters.vtOldestBlock)"
        >Data up to {{new Date($store.getters.vtOldestBlock.blockTime).toLocaleString()}} (block {{$store.getters.vtOldestBlock.blockNum}})</h5>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          v-if="!$store.getters.vtLoadingTransactions && $store.getters.vtStreamStarted"
          @click="loadMoreData"
        >Load more data</button>
      </div>
    </div>
  </div>
</template>

<script>
import { SweetModal, SweetModalTab } from "sweet-modal-vue";
import Picker from "@/components/Picker";
import VoteBox from "@/components/VoteBox";
import { numberWithCommas, isEmpty } from "@/lib/misc";
import LineChart from "@/components/Charts/LineChart";
import BarChart from "@/components/Charts/BarChart";
import * as chartConfigs from "@/components/Charts/config";
import RotateLoader from "vue-spinner/src/PulseLoader.vue";
export default {
  name: "nodestatus",
  components: {
    SweetModal,
    SweetModalTab,
    Picker,
    VoteBox,
    LineChart,
    BarChart,
    RotateLoader
  },
  data() {
    return {
      producer_filter: "",
      proxy_filter: ""
    };
  },
  methods: {
    test() {
      console.log(this.$data.producer_filter, this.getProducers());
    },
    numberWithCommas,
    isEmpty,
    selectTab(tabname) {
      this.$store.commit("setVTSelectedTab", tabname);
    },

    getProducers() {
      return this.$store.getters.vtProducers.filter(item => {
        return item.key
          .toLowerCase()
          .includes(this.$data.producer_filter.toLowerCase());
      });
    },

    get24hVotes(producer) {
      let result = this.$store.getters.vtProducers24.filter(item => {
        return item.key == producer;
      });
      if (result.length == 0) return 0;
      return result[0].json.real_votes;
    },

    getProxies() {
      return this.$store.getters.vtProxies.filter(item => {
        return item.owner
          .toLowerCase()
          .includes(this.$data.proxy_filter.toLowerCase());
      });
    },

    historicVoterData(bp) {
      return {
        extraOptions: chartConfigs.historicVotersChartOptions,
        chartData: {
          labels: this.$store.getters.vtHistory("num_voters").dates,
          legend: {
            display: false
          },
          datasets: [
            {
              label: "Proxy Votes",
              fill: true,
              borderColor: "#42b883",
              pointBackgroundColor: "#42b883",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#42b883",
              pointRadius: 4,
              data: this.$store.getters.vtHistory("num_voters").values
            }
          ]
        }
      };
    },
    historicVoteData(bp) {
      return {
        extraOptions: {
          ...chartConfigs.historicVoteChartOptions,
          legend: {
            display: true,
            position: "bottom"
          }
        },
        chartData: {
          labels: this.$store.getters.vtHistory("proxy_votes").dates,
          datasets: [
            {
              label: "Proxy Votes",
              fill: true,
              borderColor: "#42b883",
              pointBackgroundColor: "#42b883",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#42b883",
              pointRadius: 4,
              data: this.$store.getters.vtHistory("proxy_votes").values
            },
            {
              label: "Direct votes",
              fill: true,
              borderColor: "#F92D81",
              pointBackgroundColor: "#F92D81",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#F92D81",
              pointRadius: 4,
              data: this.$store.getters.vtHistory("direct_votes").values
            },
            {
              label: "Total Votes",
              fill: true,
              borderColor: "#1d8cf8",
              pointBackgroundColor: "#1d8cf8",
              pointBorderColor: "rgba(255,255,255,0)",
              pointHoverBackgroundColor: "#1d8cf8",
              pointRadius: 4,
              data: this.$store.getters.vtHistory("total_votes").values
            }
          ]
        }
      };
    },
    loadMoreData() {
      this.$store.dispatch("getMoreActions");
    }
  },
  mounted() {
    if (!this.$store.getters.vtInitialized) {
      this.$store.dispatch("initVT");
    }
  }
};
</script>

<style scoped>
.hidden {
  display: none;
}

.vote-change {
  font-size: 0.7rem;
}

.powered {
  display: inline-block;
  margin-bottom: 0px;
  line-height: 1;
}
</style>

<style scoped>
</style>
