export function numberWithCommas(x) {
    if (!x) return '';
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export function isEmpty(obj) {
    if (obj == undefined) return 1;
    if (obj == null) return 1;
    return Object.keys(obj).length === 0;
}

export function realVotes(votes) {
    var timestamp_epoch = 946684800000;
    var dates_ = (Date.now() / 1000) - (timestamp_epoch / 1000);
    var weight_ = parseInt(dates_ / (86400 * 7)) / 52; //86400 = seconds per day 24*3600
    var voteWeight = Math.pow(2, weight_);
    return (votes / voteWeight / 10000);
}