<template>
  <div class="card">
    <!---->
    <div class="card-body vote-card-body">
      <p v-if="actionData.proxy">
        <a
          :href="'https://wax.bloks.io/account/'+actionData.voter"
          target="_blank"
        >{{actionData.voter}}</a>&nbsp;
        <a :href="'https://wax.bloks.io/transaction/'+trxId" target="_blank">voted</a>
        for proxy
        <router-link :to="'/voteauditor/proxy/'+actionData.proxy">{{actionData.proxy}}</router-link>
      </p>
      <p v-else>
        <a
          :href="'https://wax.bloks.io/account/'+actionData.voter"
          target="_blank"
        >{{actionData.voter}}</a>&nbsp;
        <a :href="'https://wax.bloks.io/transaction/'+trxId" target="_blank">voted</a>
        for {{actionData.producers.length}} producers:
      </p>
      <p v-if="!actionData.proxy">
        <code v-for="(producer, index) in actionData.producers" :key="producer">
          <router-link class="bp-list-link" :to="'/voteauditor/producer/'+producer">{{producer}}</router-link>
          <span v-if="(index+1)<actionData.producers.length">,</span>
        </code>
        <span v-if="!actionData.producers.includes(currentProducer)">
          <br />
          <code class="crossed-out">{{currentProducer}}</code>
        </span>
      </p>
      <p>
        <span class="text-success" v-if="voteChange>0">+{{numberWithCommas(voteChange)}} votes</span>
        <span class="text-muted" v-if="voteChange==0">no vote change</span>
        <span class="text-danger" v-if="voteChange<0">{{numberWithCommas(voteChange)}} votes</span>
      </p>
    </div>
    <div class="card-footer">
      <hr class="vote-hr" />
      <div class="stats">
        <div>
          <i class="tim-icons icon-watch-time"></i>
          {{date.toLocaleString()}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { numberWithCommas, isEmpty } from "@/lib/misc";
export default {
  props: {
    date: {
      type: Date,
      required: true
    },
    actionData: {
      required: true
    },
    trxId: {
      required: true
    },
    voteChange: {
      required: false
    },
    currentProducer: {
      required: false
    }
  },
  methods: {
    numberWithCommas
  }
};
</script>

<style scoped>
.vote-hr {
  margin-top: 0px;
}
.vote-card-body {
  padding-bottom: 0px;
}
.bp-list-link {
  color: #f3a4b5;
}
.crossed-out {
  text-decoration: line-through;
}
</style>